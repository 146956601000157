import { Subject } from 'rxjs';
import axios from 'axios';

class Api {
  constructor() {
    this.token = sessionStorage.getItem('token');
    this.errors = new Subject();
    this.root = process.env.API_ROOT;
  }

  isAuthorized() {
    return this.token !== null;
  }

  getAuthOptions() {
    return {
      headers: {
        Authorization: `JWT ${this.token}`,
      },
    };
  }

  handleError(error) {
    const { response } = error;
    this.errors.next(error);
    switch (response.status) {
      case 401:
        this.logout();
        break;
      default:
    }
  }

  login(username, password) {
    return axios.post(`${this.root}/api-token-auth/`, {
      username,
      password,
    }).then(({ data }) => {
      const { token } = data;
      sessionStorage.setItem('token', token);
      this.token = token;
    }).catch((error) => this.handleError(error));
  }

  logout() {
    sessionStorage.removeItem('token');
    this.token = null;
  }

  getParticipants() {
    return axios.get(`${this.root}/patients/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  createParticipant(participant) {
    return axios.post(`${this.root}/patients/`, participant, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  updateParticipant(url, participant) {
    return axios.put(url, participant, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getGenderOptions() {
    return axios.get(`${this.root}/gender/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getDiagnosisOptions() {
    return axios.get(`${this.root}/diagnosis/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getMedicationOptions() {
    return axios.get(`${this.root}/medication/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getProtocol(url) {
    return axios.get(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getProtocols(patientId) {
    const options = {
      ...this.getAuthOptions(),
      params: {
        patient: patientId,
      },
    };
    return axios.get(`${this.root}/protocols/`, options)
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  createProtocol(protocol) {
    return axios.post(`${this.root}/protocols/`, protocol, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  updateProtocol(url, updates) {
    return axios.patch(url, updates, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getHeadsetStates() {
    return axios.get(`${this.root}/head_state/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getDevices() {
    return axios.get(`${this.root}/devices/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getTrials(protocolId) {
    return axios.get(`${this.root}/trials/?protocol=${protocolId}`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getEyeTypeOptions() {
    return axios.get(`${this.root}/eye_type/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getGroundTypeOptions() {
    return axios.get(`${this.root}/ground_type/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  createTrial(trial) {
    return axios.post(`${this.root}/trials/`, trial, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  updateTrial(trialUrl, trial) {
    return axios.patch(`${trialUrl}`, trial, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  deleteTrial(url) {
    return axios.delete(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getStroopTypeOptions() {
    return axios.get(`${this.root}/stroop_type/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getReport(url) {
    return axios.get(url, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  getUserInfo() {
    return axios.get(`${this.root}/user_info/`, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }

  createCrossToken(_payload) {
    const payload = _payload !== undefined ? _payload : {};
    return axios.post(`${this.root}/cross-token/`, payload, this.getAuthOptions())
      .then(({ data }) => data)
      .catch((error) => this.handleError(error));
  }
}

export default new Api();
