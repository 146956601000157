import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    background: {
      paper: '#E6F5FD',
      default: '#FFF',
    },
    common: {
      white: '#FFF',
      black: '#000',
    },
    primary: {
      main: '#00CCFF',
    },
    secondary: {
      main: '#AEB7B6',
    },
    error: {
      main: '#89181A',
    },
    warning: {
      main: '#FEEA02',
    },
    success: {
      main: '#6ABD46',
    },
    divider: '#AEB7B6',
    text: {
      primary: '#4F4F4F',
    },
  },
  spacing: 8,
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
    h1: {
      fontFamily: '"Oxygen", sans-serif',
    },
    h2: {
      fontFamily: '"Oxygen", sans-serif',
    },
    h3: {
      fontFamily: '"Oxygen", sans-serif',
    },
    h4: {
      fontFamily: '"Oxygen", sans-serif',
    },
    h5: {
      fontFamily: '"Oxygen", sans-serif',
    },
    h6: {
      fontFamily: '"Oxygen", sans-serif',
    },
  },
});

export default theme;
