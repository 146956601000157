import React, {
  Suspense, lazy, useState, useEffect,
} from 'react';
import {
  HashRouter,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Api from '../Api';
import theme from './theme';
import ErrorBoundary from '../ErrorBoundary';

const Login = lazy(() => import('../Login/Login'));
const Dashboard = lazy(() => import('../Dashboard/Dashboard'));

const renderBaseRoute = () => {
  const path = Api.isAuthorized() ? '/dashboard' : '/login';
  return (<Redirect to={path} />);
};

const LoginRoute = () => (
  Api.isAuthorized()
    ? (<Redirect to="/dashboard" />)
    : (<Login />)
);

const renderDashboard = () => (
  Api.isAuthorized()
    ? (<Dashboard />)
    : (<Redirect to="/login" />)
);

const App = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  useEffect(() => (
    Api.errors.subscribe(
      (error) => {
        setSnackbarMessage(error.message);
        setSnackbarOpen(true);
      },
    ).unsubscribe
  ), []);
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Suspense fallback={<span>Loading...</span>}>
          <HashRouter>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/" render={renderBaseRoute} />
                <Route path="/login" component={LoginRoute} />
                <Route path="/dashboard" render={renderDashboard} />
              </Switch>
            </ErrorBoundary>
          </HashRouter>
          <Snackbar
            autoHideDuration={5000}
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              elevation={6}
              severity="error"
              variant="filled"
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Suspense>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
